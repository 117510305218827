// @codekit-prepend "/js/stripe.js";
// @codekit-append "/js/auth.js"

var firebaseConfig = {
    apiKey: "AIzaSyAG2dS4nPR00w6pQpxBq76YmqI9nifUS-U",
    authDomain: "allaboard-2fd6c.firebaseapp.com",
    databaseURL: "https://allaboard-2fd6c-default-rtdb.europe-west1.firebasedatabase.app/",
    projectId: "allaboard-2fd6c",
    storageBucket: "allaboard-2fd6c.appspot.com",
    messagingSenderId: "761396474631",
    appId: "1:761396474631:web:4c1a312002386d2ca24ba2",
    measurementId: "G-0G7MSHNGDL"
};

var firebaseApp = firebase.initializeApp(firebaseConfig);
var db = firebase.database();
var firestore = firebase.firestore();
var ref = db.ref("/");
var storage = firebase.storage();
var storageRef = storage.ref();
var imagesRef = storageRef.child("images");
var resourcesRef = storageRef.child("resources");
var lessonPlansRef = storageRef.child("lessonPlans");

